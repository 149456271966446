<template>
  <div>
    <FormulaBanner :formulaCode="formulaCode" :title="title" />
    <div class="index">
      <!-- 表单 -->
      <div>
        <div class="top_nav"><span></span>{{ title }}</div>
        <div class="formBody">
          <!-- 表单 -->
          <MyPatient
            :memberId="memberId"
            :selectValue="selectValue"
            @change="userChange"
          />

          <TemplateFiveList
            :list-data="listData"
            :selectValue="listSelect"
            :type="'radio'"
            @change="listChange"
          ></TemplateFiveList>
          <div v-if="compute === true" style="margin: 16px;display: flex;justify-content: center;">
            <van-button
              block
              class="formula-template-button"
              type="info"
              @click="submit"
              style="width:90px"
              >提交</van-button
            >
          </div>
          <!--          <div v-if="countText || countText !== ''" class="CalculationResultsBox">-->
          <!--            <div class="CalculationResults"><span>计算结果</span></div>-->
          <!--            &lt;!&ndash; 具体标准 &ndash;&gt;-->
          <!--            <div>-->
          <!--              <div class="CalculationDetail"><span></span>{{ result }}分 {{ countText }}</div>-->
          <!--            </div>-->
          <!--          </div>-->
        </div>
      </div>
      <CalculationResults
        :countText="countValue"
        v-if="show"
      ></CalculationResults>
      <!-- 后台设置的值 -->
      <div v-if="tableData.explain && tableData.explain.length > 0">
        <div
          v-for="(item, index) in tableData.explain"
          :key="index"
          class="StandardDescription"
        >
          <div class="top_nav"><span></span>{{ item.title }}</div>
          <div class="StandardDeatil">
            <div v-html="item.value" style="overflow-x: auto"></div>
          </div>
        </div>
      </div>
      <!--      <FormulaExplain  :explain-data="tableData.explain"></FormulaExplain>-->
      <!-- 参考文献 -->
      <FormulaReferences
        :references-data="tableData.references"
      ></FormulaReferences>
    </div>

    <van-popup v-model="showPicker" position="bottom">
      <van-picker
        :columns="columns"
        show-toolbar
        title="应激指数"
        @cancel="onCancel"
        @confirm="onConfirm"
      />
    </van-popup>
  </div>
</template>

<script>
import MyPatient from "@/components/MyPatient.vue";
import FormulaBanner from "@/components/FormulaBanner.vue";
import { Toast } from "vant";
import { getAdmins } from "@/utils/adminDate";
import { setById } from "@/service/base";
import {
  getFormula,
  getFormulaHuiXian,
  postFormula,
} from "@/service/BodyMassIndex_api";
// import FormulaExplain from "@/components/template1/FormulaExplain";
import FormulaReferences from "@/components/template1/FormulaReferences";
import TemplateFiveList from "@/components/Template5/TemplateFiveList";
import CalculationResults from "@/components/CalculationResults.vue";

export default {
  name: "medicalSearch",
  data() {
    return {
      compute:true,
      show: false,
      listSelect: [],
      listData: [
        {
          title: "年龄",
          columns: ["<60岁", "60-79岁", "≥80岁"],
          fenzhi: [0, 1, 2], //分值顺序必须和选项顺序保持一致！！！！！！！！！！！！！！！
        },
        {
          title: "休克状况",
          columns: [
            "无休克 (收缩压>100mmHg，心率< 100bpm)",
            "心动过速 (收缩压>100mmHg，心率> 100bpm)",
            "低血压(收缩压< 100mmHg)",
          ],
          fenzhi: [0, 1, 2], //分值顺序必须和选项顺序保持一致！！！！！！！！！！！！！！！
        },
        {
          title: "并发症",
          columns: [
            "无",
            "心力衰竭、冠脉疾病和其他严重伴发疾病",
            "肝/肾衰竭和肿瘤播散",
          ],
          fenzhi: [0, 2, 3], //分值顺序必须和选项顺序保持一致！！！！！！！！！！！！！！！
        },
        {
          title: "内镜诊断",
          columns: [
            "Mallory-Weiss综合征或无病变及出血征象",
            "溃疡等其他病变",
            "肝/上消化道恶性肿瘤",
          ],
          fenzhi: [0, 1, 2], //分值顺序必须和选项顺序保持一致！！！！！！！！！！！！！！！
        },
        {
          title: "瘀斑",
          columns: [
            "无或仅有黑斑",
            "上消化道血液潴留，粘附血凝块，血管显露或喷血",
          ],
          fenzhi: [0, 2], //分值顺序必须和选项顺序保持一致！！！！！！！！！！！！！！！
        },
      ],
      formulaCode: "",
      shengao: "",
      yingjizhishu: "",
      title: "",
      memberId: "1111",
      selectValue: "",
      username: "",
      value: "",
      showPopover: false,
      showPicker: false,
      columns: ["立即治疗", "加强监测", "维持和监护"],
      nianling: "",
      xingbie: null,
      tableData: {},
      countText: "",
      patientId: null,
      result: null,
      yingjizhishuzhi: "",
      tizhong: "",
      xueqingnanongdu: "",
      fenzhiList: [],
      countValue: "",
    };
  },
  components: {
    CalculationResults,
    FormulaBanner,
    MyPatient,

    FormulaReferences,
    TemplateFiveList,
  },
  async created() {
    await this.test();
    await this.getTableData();
  },

  methods: {
    listChange(result, fenzhi) {
      this.show = false;
      this.listSelect = result; //结果数组
      this.fenzhiList = fenzhi; //分值数组
      console.log(result);
    },

    coumputTetxt() {
      this.show = true;
      let fenshu = 0;
      function calculateScore(data, select) {
        select.value.forEach((item) => {
          const index = data.columns.indexOf(item);
          if (index !== -1) {
            fenshu += data.fenzhi[index];
          }
        });
      }

      this.listSelect.forEach((value) => {
        const data = this.listData.find((d) => d.title === value.title);
        if (data) {
          calculateScore(data, value);
        }
      });

      this.result = fenshu;
      this.countValue = `${fenshu}分`;
    },
    async test() {
      //像接口头部传用户信息
      let arrays = await getAdmins(
        this.$route.query.memberId,
        this.$route.query.channel
      );
      this.channel = arrays.os;
      this.version = arrays.version;
      this.ip = arrays.ip;
      await setById(arrays);
    },
    async getTableData() {
      const data = {
        channel: this.$router.currentRoute.query.channel,
        code: "ZXMAIHOK",
      };
      const res = await getFormula(data.channel, data.code);
      if (res.code === 0) {
        this.tableData = res.data;
        this.formulaCode = res.data.code;
        this.title = res.data.chineseName;
      }
      if (
        this.$router.currentRoute.query.location === "YXGS_RECORD_LIST" &&
        this.$router.currentRoute.query.logId
      ) {
        // this.compute = false
        const data = {
          id: this.$router.currentRoute.query.logId,
        };
        const res = await getFormulaHuiXian(data);
        if (res.code === 0) {
          this.listSelect = res.data.content.data;
          if (res.data.patientId)
            this.selectValue = {
              id: res.data.patientId,
              name: res.data.patientName,
            };
          this.result = res.data.result.value;
          this.countText = res.data.result.result;
          this.countValue = `${res.data.result.value}分 ${res.data.result.result}`;
          this.show = true;
          // this.coumputTetxt()
        }
      }
    },
    async submit() {
      if (this.listSelect.length!==this.listData.length) {
        Toast.fail('请完善选择')
        return
      }
      this.coumputTetxt();
      const data = {
        id:
          this.$router.currentRoute.query.location === "YXGS_RECORD_LIST"
            ? this.$router.currentRoute.query.logId
            : null,
        patientId: this.patientId,
        columnCode: this.tableData.columnCode,
        formulaCode: this.tableData.code,
        channel: this.$router.currentRoute.query.channel,
        location: this.$router.currentRoute.query.location,
        content: {
          data: this.listSelect,
        },
        result: {
          value: this.result,
          result: this.countText,
          DisplayResults:this.countValue
        },
      };
      const res = await postFormula(data);
      if (res.code === 0) {
        Toast.success("提交成功");
      } else {
        Toast.fail(res.message);
      }
    },
    calculateAge(birthDateString) {
      const today = new Date();
      const birthDate = new Date(birthDateString);
      let age = today.getFullYear() - birthDate.getFullYear();
      // 如果今天的日期还没到生日月份和日期，则年龄减一
      if (
        today.getMonth() < birthDate.getMonth() ||
        (today.getMonth() === birthDate.getMonth() &&
          today.getDate() < birthDate.getDate())
      ) {
        age--;
      }
      return age;
    },
    // 开参考文献链接
    openLink(u) {
      if (!u) {
        return;
      }
      //获取系统版本
      let userAI = navigator.userAgent;
      const isAndroid =
        userAI.indexOf("Android") > -1 || userAI.indexOf("Linux") > -1; //Android终端
      const isIOS = !!userAI.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // ios终端
      if (isAndroid) {
        // alert('安卓操作系统')
        // 这个是安卓操作系统
        window.open(u);
      }
      if (isIOS) {
        // 这个是ios操作系统
        // alert('ios操作系统')
        window.location.href = u;
      }
    },
    yingjiClick() {
      this.showPicker = true;
    },
    onConfirm(value) {
      this.yingjizhishu = value;
      this.showPicker = false;
    },
    onCancel() {
      this.showPicker = false;
    },
    userChange(data) {
      if (data.birthday && data.birthday !== "") {
        this.nianling = this.calculateAge(data.birthday);
      } else {
        this.nianling = "";
      }
      this.xingbie = data.sex;
      this.patientId = data.id;
      this.selectValue = data;
    },
  },
};
</script>

<style lang="scss" scoped>
//::v-deep .box-div-dis-zi{
//  margin: 12px 0 !important;
//}
.index {
  background: #f6f6f6;
  padding: 10px 15px;
  min-height: 100vh;

  .StandardDescription {
    margin-top: 10px;

    .top_nav {
      font-size: 16px;
      color: #333333;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: start;
      margin-bottom: 10px;

      span {
        display: inline-block;
        width: 5px;
        height: 15px;
        background: #259beb;
        margin-right: 5px;
      }
    }

    .StandardDeatil {
      width: 100%;
      padding: 15px 10px;
      //   height: 50px;
      background-color: #fff;
      box-sizing: border-box;
    }
  }

  .top_nav {
    font-size: 16px;
    color: #333333;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: start;
    margin-bottom: 10px;

    span {
      display: inline-block;
      width: 5px;
      height: 15px;
      background: #259beb;
      margin-right: 5px;
    }
  }

  .formBody {
    background-color: #fff;
    padding-bottom: 10px;

    .CalculationResultsBox {
      padding: 0 8px;

      .CalculationResults {
        font-size: 16px;
        color: #333333;
        font-weight: bold;
        display: flex;
        justify-content: center;
        margin-bottom: 10px;
      }

      .CalculationDetail {
        font-size: 15px;
        color: #333333;
        // font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: start;
        margin-bottom: 10px;
        background-color: #f6f6f6;
        // height: 16px;
        span {
          display: inline-block;
          width: 3px;
          height: 24px;
          background: #fe9712;
          margin-right: 5px;
        }
      }
    }
  }

  .titles {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 50px;
    border-bottom: 1px solid #f0f0f0;
    padding: 0 10px;
    box-sizing: border-box;

    .titlesName {
      font-size: 16px;

      i {
        color: red;
      }
    }

    .information {
      font-size: 16px;
      margin-right: 4px;

      .PleaseSelect {
        color: #b9b9b9;
      }

      .vanCell {
        width: 50px;
        padding: 0;
        height: 100%;
      }
    }

    .vBotton {
      width: 60px;
    }

    .unit {
      height: 50px;
      //   line-height: 50px;
      display: flex;
      align-items: center;
      font-size: 16px;
      width: 20px;
    }

    .vanIcon {
      font-size: 13px;
      color: #a8a8a8;
      font-weight: 650;
    }
  }
}

::v-deep .van-cell {
  padding: 0px 0px;
}

::v-deep .van-field__control {
  height: 50px;
  font-size: 16px;
}

::v-deep .van-field__error-message {
  position: absolute;
  top: 30px;
  left: 10px;
}

.StandardDeatil2 {
  width: 100%;
  padding: 15px 10px;
  padding-bottom: 1px;
  //   height: 50px;
  background-color: #fff;
  box-sizing: border-box;

  .references {
    margin-bottom: 15px;
  }
}

::v-deep p {
  padding: 0;
  margin: 0;
}

.Abutton {
}
</style>
